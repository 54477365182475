import { Component, Inject } from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { EMPTY, catchError, tap } from 'rxjs';
import { ComponentApiService } from 'src/app/core/api/component/component-api.service';

@Component({
  selector: 'app-attach-created-component',
  templateUrl: './attach-created-component.component.html',
})
export class AttachCreatedComponent {

  componentName: string = '';
  public pageCtrl: FormControl;
  public options: any[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AttachCreatedComponent>,
    private _toastService: ToastService,
    private _componentApiService: ComponentApiService
  ) {
    this.options = this.data?.options;
    this.componentName = this.data?.componentName;
    this.pageCtrl = new FormControl('', Validators.required);
  }

  save() {
    const selectedPage = this.options.find(f => f.name === this.pageCtrl.value);

    const obsToSend = this._componentApiService.attachComponentToPage(
          selectedPage?.projectId,
          {
            componentId:this.data?.componentId,
            pageId:selectedPage?.id,
          });
 
    obsToSend
      .pipe(
        catchError(() => {
          this._toastService.show(
            'Something went wrong while attaching component.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `Component attached successfully to ${selectedPage?.name} page.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }

}
