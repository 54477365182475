import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';
import { IAttachDetachComponentRequest, IAttachDetachPageRequest, IComponentRequest, IComponentResponse } from '../../models/component';
import { IMessageResponse } from '../../models/common';

@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the component controller
 */
export class ComponentApiService {

  constructor(private http: HttpClient) {}

  /**
   * @description
   * Create new Component
   * 
   * @param projectId The ID of Project to associate the component with
   * @param component Component details to add
   */
  createComponent(projectId: number, data: IComponentRequest): Observable<IComponentResponse> {
    return this.http.post<IComponentResponse>(`${ApiUrl.Components}/${projectId}`, data);
  }

  /**
   * @description
   * Return a list of Components by ProjectId
   * 
   * @param projectId The ID of Project to get all components
   */
  getComponents(projectId: number): Observable<IComponentResponse[]> {
    return this.http.get<IComponentResponse[]>(`${ApiUrl.Components}/${projectId}`);
  }

  /**
   * @description
   * Return a Component by ComponentId and ProjectId
   * 
   * @param projectId The ID of the project to get the component from
   * @param componentId The ID of the component to be returned
   */
  getComponent(projectId: number, componentId: number): Observable<IComponentResponse> {
    return this.http.get<IComponentResponse>(`${ApiUrl.Components}/${projectId}/${componentId}`);
  }
  /**
   * @description
   * Update a Component
   * 
   * @param projectId The ID of Project to update the component in
   * @param componentId The ID of the component to be updated
   * @param data Component to be updated
   */
  updateComponent(projectId: number, componentId: number, data: IComponentRequest): Observable<IComponentResponse> {
    return this.http.put<IComponentResponse>(`${ApiUrl.Components}/${projectId}/${componentId}`, data);
  }

  /**
   * @description
   * Delete a Component by ComponentId and ProjectId
   * 
   * @param projectId The ID of the project to delete the component from
   * @param componentId The ID of the component to be deleted
   */
  deleteComponent(projectId: number, componentId: number): Observable<IMessageResponse> {
    return this.http.delete<IMessageResponse>(`${ApiUrl.Components}/${projectId}/${componentId}`);
  }

  /**
   * @description
   * Attach component to page
   * 
   * @param projectId  The ID of the project in which the component is contained
   * @param data Component to be attached to page
   */
  attachComponentToPage(projectId: number, data: IAttachDetachPageRequest): Observable<IMessageResponse> {
    return this.http.patch<IMessageResponse>(`${ApiUrl.Components}/${projectId}/attach/componentToPage`, data);
  }

  /**
   * @description
   * Attach a Component to another Component
   * 
   * @param projectId  The ID of the project in which the component is contained
   * @param data Component to be attached to component
   */
  attachComponentToComponent(projectId: number, data: IAttachDetachComponentRequest): Observable<IMessageResponse> {
    return this.http.patch<IMessageResponse>(`${ApiUrl.Components}/${projectId}/attach/componentToComponent`, data);
  }

  /**
   * @description
   * Detach a Component from a Page
   * 
   * @param projectId  The ID of the project in which the component is contained
   * @param data Component to be detached from page
   */
  detachComponentFromPage(projectId: number, data: IAttachDetachPageRequest): Observable<IMessageResponse> {
    return this.http.patch<IMessageResponse>(`${ApiUrl.Components}/${projectId}/detach/componentFromPage`, data);
  }

  /**
   * @description
   * Detach a Component from another Component
   * 
   * @param projectId  The ID of the project in which the component is contained
   * @param data Component to be detached from a components
  */
  detachComponentFromComponent(projectId: number, data: IAttachDetachComponentRequest): Observable<IMessageResponse> {
    return this.http.patch<IMessageResponse>(`${ApiUrl.Components}/${projectId}/detach/componentFromComponent`, data);
  }

}
