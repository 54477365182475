import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl } from '../../enums/api-url.enum';
import { Observable, firstValueFrom } from 'rxjs';
import { IDownloadProjectResponse, IProjectRequest, IProjectResponse, IUploadProjectRequest } from '../../models/project';
import { IMessageResponse } from '../../models/common';

/**
 * @service
 * @description
 * Handles requests for the projects controller
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {

  constructor(private http: HttpClient) {}

  /**
   * @description
   * Create a new Project
   * 
   * @param data Project to be created
   */
  createProject(data: IProjectRequest): Observable<IProjectResponse> {
    return this.http.post<IProjectResponse>(`${ApiUrl?.Projects}`, data);
  }

  /**
   * @description
   * Return a list of Projects
   * 
   */
  getProjects(): Observable<IProjectResponse[]> {
    return this.http.get<IProjectResponse[]>(`${ApiUrl?.Projects}`);
  }

  /**
   * @description
   * Return a Project by ProjectId
   * 
   * @param projectId The ID of Project to be returned
   */
  getProject(projectId: number): Observable<IProjectResponse> {
    return this.http.get<IProjectResponse>(`${ApiUrl?.Projects}/${projectId}`);
  }

  /**
   * @description
   * Update an existing project
   * 
   * @param projectId The project Id to update
   * @param data Project to be updated
   */
  updateProject(projectId: number, data: IProjectRequest): Observable<IProjectResponse> {
    return this.http.put<IProjectResponse>(`${ApiUrl?.Projects}/${projectId}`, data);
  }

  /**
   * @description
   * Delete project by id
   * 
   * @param projectId The ID of Project to be deleted
   */
  deleteProject(projectId: number): Observable<IMessageResponse> {
    return this.http.delete<IMessageResponse>(`${ApiUrl?.Projects}/${projectId}`);
  }

  /**
   * @description
   * Create a new Project by uploading the Page Object Model
   * 
   */
  uploadProject(data: IUploadProjectRequest): Observable<IProjectResponse> {
    return this.http.post<IProjectResponse>(ApiUrl.ProjectsUpload, data);
  }

  /**
   * @description
   * Create a new Project by cloning an existing one
   * 
   * @param projectId The ID of the project to be cloned
   * @param data Project to be cloned
   */
  cloneProject(projectId: number, data: IProjectRequest): Observable<IProjectResponse> {
    return this.http.post<IProjectResponse>(`${ApiUrl.ProjectsClone}/${projectId}`,data);
  }


  /**
   * @description
   * Download a Project as Page Object Model by ProjectId
   * 
   * @param projectId The id of the project to be downloaded
   */
  download(projectId: number): Observable<IDownloadProjectResponse> {
    return this.http.get<IDownloadProjectResponse>(`${ApiUrl.ProjectsDownload}/${projectId}`);
  }

  /**
   * @description
   * Download a Project as Page Object Model by ProjectName and ProjectEnvironment
   * 
   * @param projectName  The name of the project to be downloaded
   * @param ProjectEnvironment The environment of the project to be downloaded
   */
  downloadByNameAndEnvironment(projectName: string, ProjectEnvironment: string): Observable<IDownloadProjectResponse> {
    return this.http.get<IDownloadProjectResponse>(`${ApiUrl.ProjectsDownload}/${projectName}/${ProjectEnvironment}`);
  }
}
