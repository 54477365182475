import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * @validator
 * @description
 * Defines alphanumeric without spaces validator.
 */
export class AlphanumericNoSpaceValidator {

  //#region Methods

  /**
   * @description
   * Validates if a field contains only alphanumeric characters without spaces.
   *
   * @param control The target control to validate
   * @returns ValidationErrors or null
   */
  static validate(control: AbstractControl): ValidationErrors | null {
    const alphanumericNoSpaceRegex = /^[a-zA-Z0-9]+$/;

    const value = control.value;
    const isValid = value.length === 0 || alphanumericNoSpaceRegex.test(value);
    return isValid ? null : { alphanumericNoSpace: true };
  }

  //#endregion
}