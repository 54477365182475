import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';
import { IAuthenticationRequest, IAuthenticationResponse } from '../../models/authentication';

/**
 * @service
 * @description
 * Handles authentication requests
 */
@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private http: HttpClient) { }

  /**
   * @description
   * Return the JWT token for the user
   *
   * @param data The login data
   */
  login(data: IAuthenticationRequest): Observable<IAuthenticationResponse> {
    return this.http.post<IAuthenticationResponse>(ApiUrl.Authenticate, data);
  }

}
