import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFeatureResponse, IFeatureTestRequest } from '../../models/feature';
import {environment} from 'src/environments/environment';
import { IProjectResponse } from '../../models/project';
import { ApiUrl } from '../../enums/api-url.enum';
import { TestType } from '../../enums/test-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TestApiService {

  constructor(private http: HttpClient) { }

  runJenkinsTests(
    feature: IFeatureResponse,
    projectName: string,
    projectEnv: string,
    jenkinsUrl: string
  ) {
    const params = {
      token: environment.jenkins.token,
      job: (feature.testType === TestType.API)
        ? environment.jenkins.apiTestJob
        : environment.jenkins.endToEndTestJob,
      BUDDY_PROJECT_NAME: projectName,
      BUDDY_PROJECT_ENVIRONMENT: projectEnv,
      BUDDY_PROJECT_ID: feature.projectId?.toString(),
      BUDDY_FEATURE_ID: feature.id?.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this.http.post(
      `${jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  runLoadTest(feature: IFeatureResponse, project: IProjectResponse, parameters: any) {
    const params = {
      token: environment.jenkins.token,
      job: environment.jenkins.loadTestJob,
      BUDDY_PROJECT_NAME: project.name,
      BUDDY_PROJECT_ENVIRONMENT: project.environment,
      EXECUTOR_COUNT: parameters?.executorCount || '1',
      RAMP_USERS: parameters?.rampUsers || '10',
      DURATION: parameters?.duration || '5',
      BUDDY_PROJECT_ID: project.id?.toString(),
      BUDDY_FEATURE_ID: feature.id?.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this.http.post(
      `${project?.jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  // TODO: to be removed
  runRegressionSuitTest(
    isEndTond: boolean,
    projectName: string,
    projectId: number,
    projectEnv: string,
    jenkinsUrl: string
  ) {
    const params = {
      token: environment.jenkins.token,
      job: isEndTond
        ? environment.jenkins.endToEndRegressionSuiteTestJob
        : environment.jenkins.apiRegressionSuiteTestJob,
      BUDDY_PROJECT_NAME: projectName,
      BUDDY_PROJECT_ENVIRONMENT: projectEnv,
      BUDDY_PROJECT_ID: projectId.toString(),
    };
    const param = new URLSearchParams(params).toString();
    return this.http.post(
      `${jenkinsUrl}/buildByToken/buildWithParameters?${param}`,
      {}
    );
  }

  runFeaturesEndToEndTest(projectId: number, features: IFeatureTestRequest){
    return this.http.post(
      `${ApiUrl.Cypress}/run/${projectId}`, features);
  }

  runFeaturesApiTest(projectId: number, features: IFeatureTestRequest){
    return this.http.post(
      `${ApiUrl.Karate}/run/${projectId}`, features);
  }
}
