import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageApiService } from 'src/app/core/api/page/page-api.service';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { EMPTY, catchError, tap } from 'rxjs';
import { AlphanumericNoSpaceValidator } from 'src/app/core/validators/alphanumeric-no-space.validator';
@Component({
  selector: 'app-new-page',
  templateUrl: './new-page.component.html',
})
export class NewPageComponent {
  public form: FormGroup;
  public isEdit = false;
  public get f(): any {
    return this.form.controls;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NewPageComponent>,
    private _fb: FormBuilder,
    private _pageApiService: PageApiService,
    private _toastService: ToastService
  ) {
    this.form = this._fb.group({
      name: ['', [Validators.required, AlphanumericNoSpaceValidator.validate]],
      url: ['', [Validators.required, /* TODO: Validators.pattern(RegexHelper.url) */]],
    });
    if (this.data?.isEdit) {
      this.isEdit = true;
      this.form.patchValue(this.data?.page);
    }
  }
  createPage() {
    if (!this.form.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }
    this._pageApiService
      .createPage(this.data?.projectId, { ...this.form.value })
      .pipe(
        catchError((err) => {
          this._toastService.show(
            'Something went wrong while creating this page.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `${this.form.value?.name} page successfully created.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }
  editPage() {
    if (!this.form.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }

    this._pageApiService
      .updatePage(+this.data?.projectId, this.data?.page?.id,{
        ...this.form.value
      })
      .pipe(
        catchError((err) => {
          this._toastService.show(
            'Something went wrong while updating this page.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `${this.form.value?.name} page successfully updated.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }
}
