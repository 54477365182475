<div class="dialog">
  <div mat-dialog-title class="dialog__head">
    {{ !isEdit ? "Create" : "Edit" }} Component
  </div>

  <div mat-dialog-content class="dialog__body">
    <form class="group relative" [formGroup]="form">
      <mat-form-field class="input" appearance="outline">
        <mat-icon matPrefix>badge</mat-icon>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Enter component name"
        />
        <mat-error *ngIf="f.name.errors?.required">
          Component name is required
        </mat-error>
        <mat-error *ngIf="f.name.errors?.['alphanumericNoSpace']">
          Component name must contain only alphanumeric characters without spaces.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="input" appearance="outline">
        <mat-icon matPrefix>badge</mat-icon>
        <mat-label>Selector</mat-label>
        <input
          matInput
          formControlName="selector"
          placeholder="Enter component selector"
        />
        <mat-error *ngIf="f.selector.errors?.required"
          >Component selector is required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="input" appearance="outline">
        <mat-icon matPrefix>badge</mat-icon>
        <mat-label>Stock</mat-label>
        <mat-select formControlName="isCollection">
          <mat-option *ngFor="let stock of stockType" [value]="stock">
            {{ stock?.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.isCollection.errors?.required"
          >Component stock is required</mat-error
        >
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="dialog__foot">
    <button mat-button mat-dialog-close>Cancel</button>

    <ng-container *ngIf="!this.isEdit; else editTemplate">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="createComponent()"
      >
        Create
      </button>
    </ng-container>
    <ng-template #editTemplate>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="editComponent()"
      >
        Edit
      </button>
    </ng-template>
  </div>
</div>
