import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { EMPTY, Observable, catchError, map, startWith, tap } from 'rxjs';
import { ComponentApiService } from 'src/app/core/api/component/component-api.service';

@Component({
  selector: 'app-attach-component',
  templateUrl: './attach-component.component.html',
})
export class AttachComponent {
  public componentCtrl: FormControl;
  public options: any[] = [];
  public filteredOptions: Observable<any[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AttachComponent>,
    private _toastService: ToastService,
    private _componentApiService: ComponentApiService
  ) {
    this.options = this.data?.options;
    this.componentCtrl = new FormControl('', {
      validators: [
        this._autocompleteStringValidator(this.options.map((f) => f.name)),
        Validators.required,
      ],
    });
    this.filteredOptions = this.componentCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  save() {
    if (!this.componentCtrl.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }
    const selectedComponent = this.options.find(
      (f) => f.name === this.componentCtrl.value
    );
    const obsToSend = this.data?.isPage
      ? this._componentApiService.attachComponentToPage(
        selectedComponent?.projectId,
          {
            componentId:selectedComponent?.id,
            pageId:this.data?.parentId
          }
        )
      : this._componentApiService.attachComponentToComponent(
        selectedComponent?.projectId,
        {
          componentChildId:selectedComponent?.id,
          componentParentId:this.data?.parentId
        }
        );

    obsToSend
      .pipe(
        catchError((err) => {
          this._toastService.show(
            'Something went wrong while attaching component.',
            { type: ToastType.Error }
          );
          return EMPTY;
        }),
        tap(() => {
          this._toastService.show(
            `${selectedComponent?.name} component successfully attached.`,
            {
              type: ToastType.Success,
            }
          );
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option?.name?.toLowerCase().includes(filterValue)
    );
  }

  private _autocompleteStringValidator(
    validOptions: Array<string>
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (validOptions.indexOf(control.value) !== -1) {
        return null;
      }
      return { invalidAutocompleteString: { value: control.value } };
    };
  }
}
