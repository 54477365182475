<div class="dialog">
    <div mat-dialog-title class="dialog__head">Attach Component to Page</div>
    <div mat-dialog-content class="dialog__body">
      <p>Select the page to attach {{componentName}} to</p>
      <form class="p-3 w-full">
        <mat-form-field class="w-full" appearance="outline">
          <mat-select [formControl]="pageCtrl">
            <mat-option *ngFor="let option of options" [value]="option.name">
                {{ option.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="pageCtrl.hasError('required')">
          Page is required
          </mat-error>
          <mat-error
            *ngIf="
              !pageCtrl.hasError('required') &&
              pageCtrl.hasError('invalidAutocompleteString')
            "
          >
            Page not recognized
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions class="dialog__foot">
      <button mat-button mat-dialog-close>Skip for now</button>
  
      <button
        mat-raised-button
        color="primary"
        [disabled]="!pageCtrl.valid"
        (click)="save()"
      >
      Attach
      </button>
    </div>
  </div>
  