import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Report, ReportsList } from '../../types/report-json.type';
import { ApiUrl } from "../../enums/api-url.enum";
import { IMessageResponse } from '../../models/common';
import { IReportListResponse, IReportRequest, IReportResponse } from '../../models/report';

@Injectable({
  providedIn: 'root',
})

/**
 * @service
 * @description
 * Handles requests for the report controller
 */
export class ReportsService {
  constructor(private http: HttpClient) {}

  /**
   * @description
   * Create a new Report
   * 
   * @param projectId The ID of the project to generate the report for
   * @param data Report to be created
   */
  createReport(projectId: number, data: IReportRequest): Observable<IMessageResponse> {
    return this.http.post<IMessageResponse>(`${ApiUrl.Reports}/${projectId}`, data);
  }

  /**
   * @description
   * Return a list of Reports by ProjectId
   * 
   * @param projectId The ID of Project to get all reports
   * @param page The page number to retrieve
   * @param limit The number of reports to retrieve per page. Allowed values [10, 15, 20]
   */
  getReports(projectId: number, page: number, limit: number): Observable<IReportListResponse> {
    return this.http.get<IReportListResponse>(`${ApiUrl.Reports}/${projectId}?page=${page}?limit=${limit}`);
  }

  /**
   * @description
   * Return a Report by ReportId and ProjectId
   * 
   * @param projectId The ID of the project to get the report from
   * @param reportId The ID of the report to be returned
   */
  getReport(projectId: number, reportId: number): Observable<IReportResponse> {
    return this.http.get<IReportResponse>(`${ApiUrl.Reports}/${projectId}/${reportId}`);
  }

  /**
   * @description
   * Delete a Report by ReportId and ProjectId
   * 
   * @param projectId The ID of the project to delete the report from
   * @param reportId The ID of the report to be deleted
   */
  deleteReport(projectId: number, reportId: number): Observable<IMessageResponse> { 
    return this.http.delete<IMessageResponse>(`${ApiUrl.Reports}/${projectId}/${reportId}`);
  }
}
