import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrl } from '../../enums/api-url.enum';
import { IPageRequest, IPageResponse, IPageComponentsResponse } from '../../models/page';
import { IMessageResponse } from '../../models/common';
@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the pages controller
 */
export class PageApiService {

  constructor(private http: HttpClient) {}

  /**
   * @description
   * Create a new Page
   * 
   * @param projectId The ID of Project to create the page in
   * @param data Page to be created
   */
  createPage(projectId: number, data: IPageRequest): Observable<IPageResponse> {
    return this.http.post<IPageResponse>(`${ApiUrl.Pages}/${projectId}`, data);
  }

  /**
   * @description
   * Return a list of Pages by ProjectId
   * 
   * @param projectId The ID of Project to get all pages
   */
  getPages(projectId: number): Observable<IPageComponentsResponse[]> {
    return this.http.get<IPageComponentsResponse[]>(`${ApiUrl.Pages}/${projectId}`);
  }
  /**
   * @description
   * Return a list of Pages by ProjectId
   * 
   * @param projectId The ID of the project to get the page from
   * @param pageId The ID of the page to be returned
   */
  getPage(projectId: number, pageId: number): Observable<IPageComponentsResponse> {
    return this.http.get<IPageComponentsResponse>(`${ApiUrl.Pages}/${projectId}/${pageId}`);
  }

  /**
   * @description
   * Update a Page
   * 
   * @param projectId The ID of the project in which page is contained
   * @param pageId The ID of the page to be updated
   * @param data The page to update
   */
  updatePage(projectId: number, pageId: number, data: IPageRequest): Observable<IPageResponse> {
    return this.http.put<IPageResponse>(`${ApiUrl.Pages}/${projectId}/${pageId}`, data);
  }
  
  /**
   * @description
   * Delete a Page by PageId and ProjectId
   * 
   * @param projectId The ID of the project to delete the page from
   * @param pageId The ID of the page to be deleted
   */
  deletePage(projectId: number, pageId: number): Observable<IMessageResponse> {
    return this.http.delete<IMessageResponse>(`${ApiUrl.Pages}/${projectId}/${pageId}`);
  }
}
