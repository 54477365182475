import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ToastService } from '../../services/toast/toast.service';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import {
  EMPTY,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs';
import { ProjectApiService } from 'src/app/core/api/project/project-api.service';
import { IProject } from 'src/app/core/types/project.type';



@Component({
  selector: 'app-clone-project',
  templateUrl: './clone-project.component.html',
})
export class CloneProjectComponent implements OnDestroy {
  public projectNameCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );

  private subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IProject,
    private dialogRef: MatDialogRef<CloneProjectComponent>,
    private _fb: FormBuilder,
    private _projectApiService: ProjectApiService,
    private _toastService: ToastService
  ) {
    this.subscription = new Subscription();
    this.subscription.add(
      this.projectNameCtrl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap((res: any) => {
            if (res === this.data?.name) {
              this.projectNameCtrl.setValidators(this.validateCall);
              this.projectNameCtrl.updateValueAndValidity();
            } else if (!res || res === '') {
              this.projectNameCtrl.setValidators(Validators.required);
              this.projectNameCtrl.updateValueAndValidity();
            } else {
              this.projectNameCtrl.setErrors(null);
              this.projectNameCtrl.clearValidators();
              this.projectNameCtrl.updateValueAndValidity();
            }
          })
        )
        .subscribe()
    );

    this.projectNameCtrl.setValue(data?.name);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onNameType(e: Event): void {
    if (e.isTrusted) {
      const value = (e?.target as HTMLInputElement)?.value ?? '';
      const sanitizedValue = value.replace(/\s/gi, '-');
      this.projectNameCtrl.setValue(sanitizedValue);
    }
  }
  validateCall(ctrl: AbstractControl): ValidationErrors | null {
    return {
      nameIsSameAsClonedProject: true,
    };
  }
  cloneProject() {
    if (!this.projectNameCtrl.valid) {
      this._toastService.show('Please fill all required inputs.', {
        type: ToastType.Error,
      });
      return;
    }

    this.subscription.add(
      this._projectApiService
        .cloneProject(this.data.id,
          { ...this.data, name: this.projectNameCtrl?.value },
        )
        .pipe(
          catchError((err) => {
            this._toastService.show(
              'Something went wrong while cloning this project.',
              { type: ToastType.Error }
            );
            return EMPTY;
          }),
          tap(() => {
            this._toastService.show(
              `${this.data.name} project successfully cloned.`,
              {
                type: ToastType.Success,
              }
            );
            this.dialogRef.close(true);
          })
        )
        .subscribe()
    );
  }
}
