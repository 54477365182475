import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiUrl} from "../../enums/api-url.enum";
import { IFeatureRequest, IFeatureResponse } from '../../models/feature';
import { IMessageResponse } from '../../models/common';

@Injectable({
  providedIn: 'root',
})
/**
 * @service
 * @description
 * Handles requests for the Feature controller
 */
export class FeatureService {

  constructor(private http: HttpClient) {
  }

  /**
   * @description
   * Create a new Feature
   * 
   * @param projectId The ID of Project to associate the feature with
   * @param data Feature to be created
   */

  createFeature(projectId: number, data: IFeatureRequest): Observable<IFeatureResponse> {
    return this.http.post<IFeatureResponse>(`${ApiUrl.Features}/${projectId}`, data);
  }

  /**
   * @description
   * Return a list of Features by ProjectId
   * 
   * @param projectId The ID of Project to get all features
   */
  getFeatures(projectId: string): Observable<IFeatureResponse[]> {
    return this.http.get<IFeatureResponse[]>(`${ApiUrl.Features}/${projectId}`);
  }

  /**
   * @description
   * Return a Feature by FeatureId and ProjectId
   * 
   * @param projectId The ID of the project to get the feature from
   * @param featureId The ID of the feature to be returned
   */
  getFeature(projectId: number,featureId: number): Observable<IFeatureResponse> {
    return this.http.get<IFeatureResponse>(`${ApiUrl.Features}/${projectId}/${featureId}`);
  }

  /**
   * @description
   * Update a Feature
   * 
   * @param projectId The ID of the project to update the associated feature
   * @param featureId The ID of the feature to be updated
   * @param data Feature to update
   */
  updateFeature(projectId: number, featureId: number, data: IFeatureRequest): Observable<IFeatureResponse> {
    return this.http.put<IFeatureResponse>(`${ApiUrl.Features}/${projectId}/${featureId}`, data);   
  }

  /**
   * @description
   * Delete a Feature by FeatureId and ProjectId
   * 
   * @param projectId The ID of the project to delete the feature from
   * @param featureId The ID of the feature to be deleted
   */

  deleteFeature(featureId: number, projectId: number): Observable<IMessageResponse> {
    return this.http.delete<IMessageResponse>(`${ApiUrl.Features}/${projectId}/${featureId}`);
  }
}
