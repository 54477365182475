import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getProjects, getProjectsSuccess } from './report.action';
import { ReportsService } from 'src/app/core/api/reports/reports-api.service';
import { ProjectApiService } from 'src/app/core/api/project/project-api.service';

@Injectable()
export class ReportEffects {
  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjects),
      switchMap(() => this.projectsService.getProjects()),
      map((projectList) => getProjectsSuccess({ projectList }))
    )
  );

  constructor(
    private actions$: Actions,
    private reportsService: ReportsService,
    private projectsService: ProjectApiService
  ) {}
}
